import 'classlist-polyfill'
import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'
import Bowser from 'bowser'
import DOMpurify from 'dompurify'
import unescape from 'lodash.unescape'

import {
  COOKIE_EXPIRATION_DAYS,
  GLOBAL_USER_ID_COOKIE_NAME,
  GLOBAL_USER_ID_COOKIE_DOMAIN,
  USER_ID_COOKIE_NAME,
  TRANSLATIONS,
  FALLBACK_LOCALE,
  VARIABLES_OBJECT_ATTRIBUTE_NAME,
  DEFAULT_VARIABLES_OBJECT_NAME,
  DEFAULT_CONFIG_VARIABLES_OBJECT_NAME,
  STORAGE_REGEX,
  FILE_EXTENSION_REGEX,
  LARGE_EXTENSION_RE,
  DELAY_BETWEEN_EACH_CHARACTER,
} from '@/common/common-constants'

import VueScrollTo from 'vue-scrollto'

export function getResourceUrl(chatHost, resourceName = '') {
  return `${chatHost}${resourceName}`
}

export function scrollToElement(element, container, offset = 0) {
  if (!element || !container) {
    return
  }

  VueScrollTo.scrollTo(element, {
    container,
    offset,
  })
}

export function userAllowsTracking() {
  // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/doNotTrack
  // window.doNotTrack is for >= IE11
  const dnt = navigator.doNotTrack || window.doNotTrack

  return dnt !== '1'
}

export function identifyUser() {
  const contextUid =
    window.aiaibot && window.aiaibot.context ? window.aiaibot.context.uid : null

  return {
    uid: Cookies.get(USER_ID_COOKIE_NAME) || contextUid || null,
  }
}

export function setUserId(_uid) {
  if (window.aiaibot && window.aiaibot.context) {
    window.aiaibot.context.uid = _uid
  }
}

export function createUserIdCookie(url, _uid) {
  const parsedUrl = new URL(url)
  const domain = parsedUrl.hostname
  const isHttps = parsedUrl.protocol.toLowerCase().includes('https')

  const uid = Cookies.get(USER_ID_COOKIE_NAME) || _uid || uuidv4()

  Cookies.set(USER_ID_COOKIE_NAME, uid, {
    domain,
    sameSite: isHttps ? 'None' : 'strict',
    secure: isHttps,
    expires: COOKIE_EXPIRATION_DAYS,
  })

  return uid
}

export function removeUserIdCookie(url) {
  const parsedUrl = new URL(url)
  const domain = parsedUrl.hostname
  const isHttps = parsedUrl.protocol.toLowerCase().includes('https')

  Cookies.remove(USER_ID_COOKIE_NAME, {
    domain,
    sameSite: isHttps ? 'None' : 'strict',
    secure: isHttps,
  })

  return true
}

export function createGlobalUserIdCookie(_guid) {
  const guid = Cookies.get(GLOBAL_USER_ID_COOKIE_NAME) || _guid || uuidv4()

  Cookies.set(GLOBAL_USER_ID_COOKIE_NAME, guid, {
    domain: GLOBAL_USER_ID_COOKIE_DOMAIN,
    expires: COOKIE_EXPIRATION_DAYS,
    sameSite: 'None',
    secure: true,
  })

  return guid
}

export function removeGlobalUserIdCookie() {
  Cookies.remove(GLOBAL_USER_ID_COOKIE_NAME, {
    domain: GLOBAL_USER_ID_COOKIE_DOMAIN,
    sameSite: 'None',
    secure: true,
  })

  return true
}

// Borrowed from https://github.com/Modernizr/Modernizr/blob/master/feature-detects/cookies.js
export function supportsCookies() {
  try {
    // Create cookie
    document.cookie = 'cookietest=1'

    const ret = document.cookie.indexOf('cookietest=') !== -1

    // Delete cookie
    document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT'

    return ret
  } catch (e) {
    return false
  }
}

// https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/sessionstorage.js
export function supportsSessionStorage() {
  const item = uuidv4()

  try {
    sessionStorage.setItem(item, item)
    sessionStorage.removeItem(item)
  } catch (e) {
    return false
  }

  return true
}

export function detectBrowserAndOs() {
  const userAgent = window.navigator.userAgent
  const bowser = Bowser.getParser(userAgent)
  const os = bowser.getOS()
  const browser = bowser.getBrowser()
  const platform = bowser.getPlatform()
  const mobile = platform.type.toLowerCase() === 'mobile'

  return {
    userAgent,
    os,
    browser,
    platform,
    mobile,
  }
}

export function detectUserSettings() {
  const languages = window.navigator.languages || []
  const language = window.navigator.language
  const resolution = {
    screen: {
      height: window.screen.height,
      width: window.screen.width,
    },
    window: {
      height: window.innerHeight,
      width: window.innerWidth,
    },
  }

  return {
    language,
    languages,
    resolution,
  }
}

export function detectBrowserFeatures() {
  const cookies = supportsCookies()
  const sessionStorage = supportsSessionStorage()

  return {
    cookies,
    sessionStorage,
  }
}

export const toMessage = ({ id, value = '' }, type = 'text') => {
  const message = value === null ? null : sanitize(value.toString())

  return {
    id,
    payload: { [type]: message },
  }
}

export const getBrowserLocaleCode = () => {
  const locale =
    (navigator.languages && navigator.languages[0]) || navigator.language

  // Only keep the language code, not the region as we do not support regions (yet)
  if (locale.indexOf('-') !== -1) {
    return locale.substring(0, locale.indexOf('-'))
  }

  return locale
}

export const translate = (key, locale = getBrowserLocaleCode()) => {
  if (!TRANSLATIONS[locale]) {
    locale = FALLBACK_LOCALE
  }

  const translated = TRANSLATIONS[locale][key]

  if (!translated) {
    const fallback = TRANSLATIONS[FALLBACK_LOCALE][key]

    return fallback || key
  }

  return translated
}

export const validateVariables = (variables) => {
  if (typeof variables !== 'object' || variables instanceof Array) {
    throw new Error(
      'External variables are not in the form of an object. For aiaibot Chatbot to use external variables, please provide them as an object, where the key is the variable name and the value the value of the variable to be used.',
    )
  }

  // Check that all variable names are strings
  if (
    !Object.keys(variables).every((variableName) => {
      return typeof variableName === 'string' || variableName instanceof String
    })
  ) {
    throw new Error('All external variable names must be of type string!')
  }

  // Check that all variable values are either strings or numbers
  if (
    !Object.values(variables).every((variableValue) => {
      return (
        typeof variableValue === 'string' ||
        variableValue instanceof String ||
        !isNaN(variableValue)
      )
    })
  ) {
    // eslint-disable-next-line no-console
    throw new Error(
      'All external variable values must either be of type string or number!',
    )
  }
}

export const sanitiseVariables = (variablesToSanitise) => {
  const variables = { ...variablesToSanitise }

  for (const variableName in variables) {
    variables[variableName] = sanitize(variables[variableName])
  }

  return variables
}

export const getExternalVariables = (bootstrapScript) => {
  if (!bootstrapScript) {
    return {}
  }

  // Check if the bootstrap script contains the variables object name or if the default "variable area" exists
  const hasExternalVariables =
    bootstrapScript.hasAttribute(VARIABLES_OBJECT_ATTRIBUTE_NAME) ||
    window[DEFAULT_VARIABLES_OBJECT_NAME] !== undefined

  if (!hasExternalVariables) {
    return {}
  }

  const variablesObjectName =
    bootstrapScript.getAttribute(VARIABLES_OBJECT_ATTRIBUTE_NAME) ||
    DEFAULT_VARIABLES_OBJECT_NAME

  // If a custom window variable name is used, check if it actually exists
  if (
    variablesObjectName !== DEFAULT_VARIABLES_OBJECT_NAME &&
    !window[variablesObjectName]
  ) {
    // eslint-disable-next-line no-console
    console.warn(
      `The aiaibot Chatbot script includes the optional attribute '${VARIABLES_OBJECT_ATTRIBUTE_NAME}' which points to a non-existing object called '${variablesObjectName} on the window. For this reason, external variables will not be usable within aiaibot Chatbot!`,
    )
  }

  const variables = window[variablesObjectName] || {}

  try {
    validateVariables(variables)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)

    return {}
  }

  // As an extra safety step, run every variable value through sanitization
  return sanitiseVariables(variables)
}

export const getExternalConfigVariables = () => {
  const variables = window[DEFAULT_CONFIG_VARIABLES_OBJECT_NAME] || {}

  try {
    validateVariables(variables)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)

    return {}
  }

  // As an extra safety step, run every variable value through sanitization
  return sanitiseVariables(variables)
}

export const sanitize = (value) => {
  // There is a special behaviour in DOMPurify when '&' (or other encodable characters) are used in text, they gets turned in to &amp; (encoded HTML entity).
  // However, as we rely on String equal matches in the Backend for button groups etc, this leads to an issue where a pre-configured
  // answer could not be matched.
  // See: https://github.com/cure53/DOMPurify/issues/379
  // See: https://github.com/nextcloud/server/pull/18741/files
  // Thus we de-code common HTML entities such as &amp; &gt;, &quot; and &#39; into their raw string equivalent.
  // See: https://lodash.com/docs/4.17.15#unescape
  // If more HTML entities are needed to be de-coded, the package 'he' can be used (it's quite heavy though)
  // See: https://github.com/mathiasbynens/he
  // See for allowing target - https://web.dev/external-anchors-use-rel-noopener/
  // added sip to the default value
  /* eslint-disable no-useless-escape */
  const ALLOWED_URI_REGEXP =
    /^(?:(?:(?:f|ht)tps?|sip|mailto|tel|callto|cid|xmpp|xxx):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))/i

  return unescape(
    DOMpurify.sanitize(value, { ADD_ATTR: ['target'], ALLOWED_URI_REGEXP }),
  )
}

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const toMessageId = (id) => {
  return id.split('---')[0]
}

export async function loadLargeImageUrl(url) {
  const largeUrl = getLargeImageUrl(url)

  return new Promise((resolve, reject) => {
    const img = new Image()

    img.src = largeUrl

    img.onerror = reject
    img.onload = resolve.bind(img, largeUrl)
  })
}

export function getLargeImageUrl(url) {
  if (!STORAGE_REGEX.test(url)) {
    throw new Error('Image not inside aiaibot storage')
  }

  if (LARGE_EXTENSION_RE.test(url)) {
    throw new Error('Image already large')
  }

  return url.replace(FILE_EXTENSION_REGEX, '-large$1')
}

export function calculatePrintTimeWithDelay(
  str,
  delay = DELAY_BETWEEN_EACH_CHARACTER,
) {
  const tagRegex = /<[^>]+>/g // Regex pattern to match HTML tags
  const tags = str.match(tagRegex) || [] // Extract HTML tags from the string
  const content = str.replace(tagRegex, '') // Remove HTML tags from the string

  return content.length * delay + tags.length * delay
}
